import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby"
import bannerLogo from "../../images/banner_logo.svg";
import calorieTracking from "../../images/calorie-tracking.svg";
import cameraRemote from "../../images/camera-remote.svg";
import heartRate from "../../images/heart-rate.svg";
import notifications from "../../images/notifications.svg";
import sleepMonitoring from "../../images/sleep-monitoring.svg";
import sportsModes from "../../images/sports-modes.svg";

const imageData = graphql`{
  bannerImage: file(relativePath: {eq: "banner_image.png"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`

const HeroContainer = styled.div`
`

const GridContainer = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 100%;
  padding-bottom: 10px;

  @media (min-width: 768px) {
    grid-template-columns: 1fr;
  }
 
  @media (min-width: 1200px) {
    padding-bottom: unset;
    grid-template-rows: 1fr;
    grid-gap: 40px;
  }
`

const HeroImage = styled.div`
  width: 100%;
  border: none;
  outline: none;
  padding-top: 5px;
  position: relative;

  @media (min-width: 768px) {
    grid-column: 1 / 4;
  }

  @media (min-width: 1200px) {
    padding-bottom: 45px;
    grid-column: 1 / 3;
    grid-row: 1 / 3;
    margin-bottom: 0;
  }
`

const IconContainerLarge = styled.div`
display: none;

@media (min-width: 1200px) {
  display: grid;
  visibility: visible;
  position: absolute;
  bottom: calc(100% - 85%);
  z-index: 3;
  width: 70%;
}
`

const IconContainerMed = styled.div`
  display: none;

  @media (min-width: 900px) {
    display: grid;
    grid-gap: 30px;
    padding-bottom: 45px;
    text-align: center;
  }

  @media (min-width: 1200px) {
    display: none;
  }
`

const IconContainerSmall = styled.div`
  display: grid;
  padding-bottom: 45px;
  text-align: center;

  @media (min-width: 900px) {
    display: none;
  }

`
const LogoContainer = styled.div`
  position: absolute;
  left: calc(100% - 73%);
  bottom: calc(100% - 65%);
  z-index: 3;
  width: 90%;
`

const IconColumn = styled.div`
{
  grid-row:1/1;
}`

const Banner = ({ title, info, children }) => {
  const data = useStaticQuery(imageData)
  return (
    <section>
      <HeroContainer className="container">
        <GridContainer>
          <HeroImage>
            <LogoContainer>
              <img src={bannerLogo} width="50%" alt="Banner Logo" />
            </LogoContainer>
            <IconContainerLarge>
              <IconColumn><img src={calorieTracking} height="150px" alt="Fire Icon" /></IconColumn>
              <IconColumn><img src={cameraRemote} height="150px" alt="Fire Icon" /></IconColumn>
              <IconColumn><img src={heartRate} height="150px" alt="Fire Icon" /></IconColumn>
              <IconColumn><img src={notifications} height="150px" alt="Fire Icon" /></IconColumn>
              <IconColumn><img src={sleepMonitoring} height="150px" alt="Fire Icon" /></IconColumn>
              <IconColumn><img src={sportsModes} height="150px" alt="Fire Icon" /></IconColumn>
            </IconContainerLarge>
            <div>
              <GatsbyImage image={data.bannerImage.childImageSharp.gatsbyImageData} alt="X-Five" />
            </div>
          </HeroImage>
        </GridContainer>
      </HeroContainer>

      <IconContainerMed>
        <IconColumn><img src={calorieTracking} height="150px" alt="Fire Icon" /></IconColumn>
        <IconColumn><img src={cameraRemote} height="150px" alt="Fire Icon" /></IconColumn>
        <IconColumn><img src={heartRate} height="150px" alt="Fire Icon" /></IconColumn>
      </IconContainerMed>

      <IconContainerMed>
        <IconColumn><img src={notifications} height="150px" alt="Fire Icon" /></IconColumn>
        <IconColumn><img src={sleepMonitoring} height="150px" alt="Fire Icon" /></IconColumn>
        <IconColumn><img src={sportsModes} height="150px" alt="Fire Icon" /></IconColumn>
      </IconContainerMed>

      <IconContainerSmall>
        <IconColumn><img src={calorieTracking} height="150px" alt="Fire Icon" /></IconColumn>
        <IconColumn><img src={cameraRemote} height="150px" alt="Fire Icon" /></IconColumn>
      </IconContainerSmall>

      <IconContainerSmall>
        <IconColumn><img src={heartRate} height="150px" alt="Fire Icon" /></IconColumn>
        <IconColumn><img src={notifications} height="150px" alt="Fire Icon" /></IconColumn>
      </IconContainerSmall>

      <IconContainerSmall>
        <IconColumn><img src={sleepMonitoring} height="150px" alt="Fire Icon" /></IconColumn>
        <IconColumn><img src={sportsModes} height="150px" alt="Fire Icon" /></IconColumn>
      </IconContainerSmall>
    </section>
  );
}

export default Banner
