import React from "react"
import Hero from "../components/Hero/Hero"
import Banner from "../components/Banner/Banner"
import Lifestyle from "../components/Lifestyle/Lifestyle"
import Seo from "../components/Seo"
import Carousel from "../components/Carousel/Carousel"
import Layout from "../components/Layout"
import DayNight from "../components/DayNight/DayNight"

const Index = props => {
  return (
    <>
      <Layout {...props}>
        <Seo title="Home" />
        <section className="container">
          <Hero>
            <Banner
              title=""
              info=""
            >
            </Banner>
          </Hero>
          <section className="container">
            <Carousel></Carousel>
          </section>
          <div style={{ paddingTop: 10, paddingBottom: 10 }} />
          <Lifestyle />
          <div style={{ paddingTop: 10, paddingBottom: 10 }} />
          <DayNight />
        </section>
      </Layout>
    </>
  )
}

export default Index
