import React from "react";
import styled from "styled-components"
import Slider from "react-slick";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby"

import StandoutFeatures from "../../images/standout-features.svg";

import fireIcon from "../../images/1-fire-icon.svg";
import notificationsIcon from "../../images/2-notification-icon.svg";
import heartrateIcon from "../../images/3-heartrate-icon.svg";
import extendedBatteryIcon from "../../images/4-extended-battery-icon.svg";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const query = graphql`{
  carousel1: file(relativePath: {eq: "1-black-black-faster-and-sleeker.png"}) {
    childImageSharp {
      gatsbyImageData(width: 400, layout: CONSTRAINED)
    }
  }
  carousel2: file(relativePath: {eq: "2-gold-notifications.png"}) {
    childImageSharp {
      gatsbyImageData(width: 400, layout: CONSTRAINED)
    }
  }
  carousel3: file(relativePath: {eq: "3-blue-health-and-fitness-tracking.png"}) {
    childImageSharp {
      gatsbyImageData(width: 400, layout: CONSTRAINED)
    }
  }
  carousel4: file(relativePath: {eq: "4-blush-extended-battery-life.png"}) {
    childImageSharp {
      gatsbyImageData(width: 400, layout: CONSTRAINED)
    }
  }
}
`

const Container = styled.div`
  width: 100%;
  height: calc(360px);
  position: relative;
  border: none;
  background: rgb(172,168,168);
  background: linear-gradient(90deg, rgba(172,168,168,1) 0%, rgba(47,46,46,1) 48%, rgba(33,33,33,1) 100%);

  @media (min-width: 768px) {
    height: calc(450px);
  }

  @media (min-width: 1366px) {
    height: calc(400px);
  }

`

const Watch = styled.div`
  width: calc(100% - 45%);
  position: absolute;
  top: 0;
  left: -10px;

  @media (min-width: 768px) {
    width: calc(100% - 60%);
    position: absolute;
    top: 0;
    left: 3;
  }

  @media (min-width: 1366px) {
    width: calc(100% - 60%);
    position: absolute;
    top: 0;
    left: 3;
  }
`

const CurrentRow1 = styled.div`
  position: absolute;
  display: grid;
  grid-template-columns: 100%;
  width: calc(100% - 60%);
  top: calc(100% - 98%);
  left: calc(100% - 50%);

  @media (min-width: 768px) {
  top: calc(100% - 90%);
  left: calc(100% - 55%);
  }

  @media (min-width: 1366px) {
  top: calc(100% - 90%);
  left: calc(100% - 55%);
  }
`

const CurrentHeading = styled.div`
  font-size: 12px;
  font-weight: 900;
  text-align: center;
  width: 100%;
  left: calc(100% - 60%);

  @media (min-width: 768px) {
    font-size: 18px;
  }  

  @media (min-width: 1366px) {
    font-size: 18px;
  }  
`

const CurrentRow2 = styled.div`
  position: absolute;
  display: grid;
  grid-template-columns: 30% 100%;
  width: calc(100% - 55%);
  top: calc(100% - 95%);
  left: calc(100% - 65%);
`

const CurrentIcon = styled.div`
  position: relative;
  width: 100%;
  left: calc(100% - 60%);

  @media (min-width: 768px) {
    position: relative;
    width: calc(100% - 15%);
    left: unset;
    }

  @media (min-width: 1366px) {
    position: relative;
    width: calc(100% - 15%);
    left: unset;
    }
`

const CurrentHR = styled.div`
  position: relative;
  width: 105%;
  left: 5px;
  height: 8px;
  top: calc(100% - 65%);

  @media (min-width: 768px) {
    position: relative;
    width: 120%;
    left: 5px;
    top: calc(100% - 55%);
  }  

  @media (min-width: 1366px) {
    position: relative;
    width: 110%;
    left: -50px;
    top: calc(100% - 55%);
  }  
`

const CurrentText = styled.div`
  width: 100%;
  font-size: 12px;
  text-align: center;
  padding-top: 10px;

  @media (min-width: 768px) {
    font-size: 18px;
  }  

  @media (min-width: 1366px) {
    font-size: 18px;
  }  
`

const CurrentTextBackground = styled.div`
  background-color: gray;
  position: absolute;
  height: 50%;
  width: calc(100% - 63%);
  top: calc(100% - 80%);
  left: calc(100% - 50%);
  border-radius: 8px;
  margin: auto;
  padding: 4px;

  @media (min-width: 768px) {
    height: 50%;
    width: calc(100% - 55%);
    top: calc(100% - 76%);
    left: calc(100% - 50%);
  }  

  @media (min-width: 1366px) {
    height: 40%;
    width: calc(100% - 62%);
    top: calc(100% - 76%);
    left: calc(100% - 55%);
  }  
`
const StandoutFeaturesRow = styled.div`
    display: grid;
    grid-template-columns: 32% 5% 18% 18% 18% 18%;
    width: calc(100% - 20%);
    position: absolute;
    top: calc(100% - 21%);
    left: calc(100% - 95%);

    @media (min-width: 768px) {
      display: grid;
      grid-template-columns: 30% 5% 15% 15% 15% 15%;
      grid-padding: 10px;
      position: absolute;
      top: calc(100% - 45%);
      left: 22%;
    }

    @media (min-width: 1366px) {
      display: grid;
      grid-template-columns: 30% 5% 15% 15% 15% 15%;
      grid-padding: 10px;
      position: absolute;
      top: calc(100% - 35%);
      left: 22%;
    }
  `

const StandoutFeaturesDiv = styled.div`
  position: relative;
  width: calc(120%);
`

const FireIcon = styled.div`
  width: calc(100% - 70%);
  position: relative;
  width: calc(90%);
  align-items: center;
`

const NotificationsIcon = styled.div`
  width: calc(100% - 70%);
  position: relative;
  width: calc(90%);
  align-items: center;
`

const HeartRateIcon = styled.div`
  width: calc(100% - 70%);
  position: relative;
  width: calc(90%);
  align-items: center;
`

const ExtendedBatteryIcon = styled.div`
  width: calc(100% - 70%);
  position: relative;
  width: calc(90%);
  text-align: left;
`

const SimpleSlider = () => {
  const data = useStaticQuery(query);

  var settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 2500,
    slidesToShow: 1,
    fade: true,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover: false,
    lazyLoad: true
  };

  return (
    <Slider {...settings}>
      <Container>
        <Watch>
          <GatsbyImage image={data.carousel1.childImageSharp.gatsbyImageData} alt='Watch'/>
        </Watch>
        <CurrentRow1>
          <CurrentHeading style={{ color: '#FF7336' }}>
            Faster And Sleeker Than Ever Before
          </CurrentHeading>
        </CurrentRow1>
        <CurrentRow2>
          <CurrentIcon>
            <img src={fireIcon} alt="Faster And Sleeker Than Ever Before" />
          </CurrentIcon>
          <CurrentHR>
            <hr color="#FF7336" />
          </CurrentHR>
        </CurrentRow2>
        <CurrentTextBackground>
          <CurrentText>
            X-Five Smartwatches are designed to be more durable and lighter in weight to keep up with your everyday life.  X-Five Smartwatches also come with an interchangeable strap that was optimized for active lifestyles.
        </CurrentText>
        </CurrentTextBackground>

        <StandoutFeaturesRow>
          <StandoutFeaturesDiv>
            <img src={StandoutFeatures} alt="Standout Features" />
          </StandoutFeaturesDiv>
          <div></div>
          <FireIcon><img src={fireIcon} alt="Fire Icon" /></FireIcon>
          <NotificationsIcon><img src={notificationsIcon} alt="Notifications Icon" /></NotificationsIcon>
          <HeartRateIcon><img src={heartrateIcon} alt="Heart Rate Icon" /></HeartRateIcon>
          <ExtendedBatteryIcon><img src={extendedBatteryIcon} alt="Extended Battery Icon" /></ExtendedBatteryIcon>
        </StandoutFeaturesRow>
      </Container>
      <Container>
        <Watch>
          <GatsbyImage image={data.carousel2.childImageSharp.gatsbyImageData} alt='watch'/>
        </Watch>
        <CurrentRow1>
          <CurrentHeading style={{ color: '#00ABE3' }}>
            Notifications
          </CurrentHeading>
        </CurrentRow1>
        <CurrentRow2>
          <CurrentIcon>
            <img src={notificationsIcon} alt="Faster And Sleeker Than Ever Before" />
          </CurrentIcon>
          <CurrentHR>
            <hr color="#00ABE3" />
          </CurrentHR>
        </CurrentRow2>
        <CurrentTextBackground>
          <CurrentText>
            Keep the conversations going by never missing them.  Get notifications for calls, texts, calendar events, and apps like Gmail and Facebook.  Available when phone is nearby.
        </CurrentText>
        </CurrentTextBackground>

        <StandoutFeaturesRow>
          <StandoutFeaturesDiv>
            <img src={StandoutFeatures} alt="Standout Features" />
          </StandoutFeaturesDiv>
          <div></div>
          <FireIcon><img src={fireIcon} alt="Fire Icon" /></FireIcon>
          <NotificationsIcon><img src={notificationsIcon} alt="Notifications Icon" /></NotificationsIcon>
          <HeartRateIcon><img src={heartrateIcon} alt="Heart Rate Icon" /></HeartRateIcon>
          <ExtendedBatteryIcon><img src={extendedBatteryIcon} alt="Extended Battery Icon" /></ExtendedBatteryIcon>
        </StandoutFeaturesRow>
      </Container>
      <Container>
        <Watch>
          <GatsbyImage image={data.carousel3.childImageSharp.gatsbyImageData} alt='watch'/>
        </Watch>
        <CurrentRow1>
          <CurrentHeading style={{ color: '#FF0000' }}>
            Health and Fitness Tracking
          </CurrentHeading>
        </CurrentRow1>
        <CurrentRow2>
          <CurrentIcon>
            <img src={heartrateIcon} alt="Health and Fitness Tracking" />
          </CurrentIcon>
          <CurrentHR>
            <hr color="#FF0000" />
          </CurrentHR>
        </CurrentRow2>
        <CurrentTextBackground>
          <CurrentText>
            No matter if you are at home, at the gym or outdoors.  Get to a healthier active lifestyle with built-in X-FIVE apps such as heart rate, recommended blood oxygen, sleep monitor and sports modce.  Choose a mode, tap start and crush your workout and fitness goals.  Run faster, jump higher and push harder.
        </CurrentText>
        </CurrentTextBackground>

        <StandoutFeaturesRow>
          <StandoutFeaturesDiv>
            <img src={StandoutFeatures} alt="Standout Features" />
          </StandoutFeaturesDiv>
          <div></div>
          <FireIcon><img src={fireIcon} alt="Fire Icon" /></FireIcon>
          <NotificationsIcon><img src={notificationsIcon} alt="Notifications Icon" /></NotificationsIcon>
          <HeartRateIcon><img src={heartrateIcon} alt="Heart Rate Icon" /></HeartRateIcon>
          <ExtendedBatteryIcon><img src={extendedBatteryIcon} alt="Extended Battery Icon" /></ExtendedBatteryIcon>
        </StandoutFeaturesRow>
      </Container>
      <Container>
        <Watch>
          <GatsbyImage image={data.carousel4.childImageSharp.gatsbyImageData} alt='watch'/>
        </Watch>
        <CurrentRow1>
          <CurrentHeading style={{ color: '#82B700' }}>
            Extended Battery
          </CurrentHeading>
        </CurrentRow1>
        <CurrentRow2>
          <CurrentIcon>
            <img src={extendedBatteryIcon} alt="Extended Battery" />
          </CurrentIcon>
          <CurrentHR>
            <hr color="#82B700" />
          </CurrentHR>
        </CurrentRow2>
        <CurrentTextBackground>
          <CurrentText>
            Up to seven days / 168 hours / 10,080 minutes / 604,800 seconds... Every second matters.  Super-long battery life to keep you going every step of the way.
        </CurrentText>
        </CurrentTextBackground>

        <StandoutFeaturesRow>
          <StandoutFeaturesDiv>
            <img src={StandoutFeatures} alt="Standout Features" />
          </StandoutFeaturesDiv>
          <div></div>
          <FireIcon><img src={fireIcon} alt="Fire Icon" /></FireIcon>
          <NotificationsIcon><img src={notificationsIcon} alt="Notifications Icon" /></NotificationsIcon>
          <HeartRateIcon><img src={heartrateIcon} alt="Heart Rate Icon" /></HeartRateIcon>
          <ExtendedBatteryIcon><img src={extendedBatteryIcon} alt="Extended Battery Icon" /></ExtendedBatteryIcon>
        </StandoutFeaturesRow>
      </Container>
    </Slider>
  );
}

export default SimpleSlider;
