import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby"

const imageData = graphql`query lifestyleImages {
  elevateYourStyle: file(relativePath: {eq: "elevate_your_style.png"}) {
    childImageSharp {
      gatsbyImageData(width: 600, layout: CONSTRAINED)
    }
  }
  findYourLook: file(relativePath: {eq: "find_your_look.png"}) {
    childImageSharp {
      gatsbyImageData(width: 600, layout: CONSTRAINED)
    }
  }
}
`

const HeroContainer = styled.div``

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(100px, auto);
  justify-content: start;
  grid-gap: 10px;
  padding-bottom: 10px;
`

const Photo1 = styled.div`
  /*align-self:start;*/
  grid-column:2;
  grid-row:1/2;
`

const Photo2 = styled.div`
  /*align-self:end;*/
  grid-column:1;
  grid-row:1/2;
`

const Lifestyle = ({ title, info, children }) => {
  const data = useStaticQuery(imageData)
  return (
    <section>
      <HeroContainer className="container">
        <GridContainer>
          <Photo1>
            <GatsbyImage
              image={data.elevateYourStyle.childImageSharp.gatsbyImageData}
              alt="Elevate Your Style" />
          </Photo1>
          <Photo2>
            <GatsbyImage
              image={data.findYourLook.childImageSharp.gatsbyImageData}
              alt="Find Your Look" />
          </Photo2>
        </GridContainer>
      </HeroContainer>
    </section>
  );
}

export default Lifestyle
