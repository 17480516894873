import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby"

const imageData = graphql`query {
  dayNightLooks: file(relativePath: {eq: "dayNightLooks.png"}) {
    childImageSharp {
      gatsbyImageData(width: 1200, layout: CONSTRAINED)
    }
  }
}
`

const Background = styled.div`
  width: 100%;
  border: none;
  outline: none;
  position: relative;

  img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  }
`

const Banner = () => {
  const data = useStaticQuery(imageData)
  return (
    <section>
      <div className="container">
        <Background>
          <GatsbyImage image={data.dayNightLooks.childImageSharp.gatsbyImageData}  alt="Banner Logo" />
        </Background>
      </div>
    </section>
  );
}

export default Banner
